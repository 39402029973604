import React from 'react';
import PropTypes from 'prop-types';
import darkSpinner from './tail-spin-dark.svg';
import lightSpinner from './tail-spin-light.svg';
import styles from './/styles.module.css';

const Spinner = ({ type, className, 'data-testid': testId }) =>
<img
  src={type === 'light' ? lightSpinner : darkSpinner}
  className={`${styles.spinner} ${className}`}
  data-testid={testId}
  alt="loading" />;



Spinner.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};

Spinner.defaultProps = {
  type: 'dark',
  className: ''
};

export default Spinner;